//# babelOptions minify=yes comments=no mangle=no debuginfo=yes

D.loaded.then(() =>
{
    if (window.name==='cms_iframe' && parent.cms && parent.Y.cms && parent.Y.cms.extui)
    {
        parent.Y.cms.extui.colSlider=function ()
        {
        	for (let c of document.querySelectorAll(".col-slider"))
        	{
        	    let pcont=c.querySelector(".paging-container");
            	c.insertAdjacentHTML('afterend','<button type=button title="'+parent.__("Wartungsbuttons ein/aus: strg-e")+'" class="extui">'+parent.__('Slider hinzufügen')+'</button><button type=button title="'+parent.__("Wartungsbuttons ein/aus: strg-e")+'" class="extui">'+parent.__('akt. Slider kopieren')+'</button>');
        		let slneu=c.nextElementSibling;
        		let slcpy=slneu.nextElementSibling;
        		slneu.addEventListener("click",function (ev)
        		{
        		    parent.cmslib.mkplugin(pcont,"appendChild","bhv-container","bhv-container-userdef","col",function(nid)
        		    {
        		        let m=parseInt((nid.match(/-(\d+)/)||[])[1])
        		        let nel=pcont.querySelector('#col-'+(m+1));
        		        if (nel)
        		            parent.Y.cms.extui.assistent_abschnitt(nel);
        		    });
        		    let allpages=parseInt(c.style.getPropertyValue('--allpages'));
        		    let pagesize=parseInt(getComputedStyle(c).getPropertyValue('--pagesize'));
        		    c.style.setProperty('--allpages',allpages+1);
        		    c.style.setProperty('--aktpgnum',Math.max(0,(allpages+1-pagesize)));
        		    c.querySelectorAll(".pfeil.rechts").forEach(p => p.style.visibility='hidden');
        		    c.querySelectorAll(".pfeil.links").forEach(p => p.style.visibility=allpages+1>pagesize ? 'visible' : 'hidden');
        		    
        		}.bind(c));
        		slcpy.addEventListener("click",function(ev)
        		{
        		    let aktpgnum=parseInt(getComputedStyle(c).getPropertyValue('--aktpgnum'))||0;
        		    let sid=pcont.children[aktpgnum]?.id;
        		    if (pcont.children.length>1 && sid)
        		    {
            		    parent.cms.send({
            		        cmd:'copy',
            		        page:c.closest("#cms_root") ? 'PAGE/'+cms_basepage+'.htm' : 'TEMPLATE/'+cms_basetemplate+'.asp',
            		        id:sid,
            		        refid:sid,
            		        pos:"insertafter",
            		        lang:cms_code.lang
            		    },"Fehler bei der Übertragung",function onok(obj,o)
            		    {
            		        location.reload();
            		    },"Slider wird kopiert")
        		    }
        		})
        	}
        }
    }
    
})